<template>
  <v-container fluid>
    <div class="page">
      <v-card>
        <v-card-title>
          <span class="headline" v-text="$t('passwordRestore.resetPassword')"/>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submit">
            <p>
              {{ $t('passwordRestore.pleaseEnterNewPassword') }}
            </p>
            <v-text-field
              v-model="password"
              type="password"
              filled
              required
              :rules="passwordRules"
              :label="$t('passwordRestore.newPassword')"
            />
            <v-text-field
              v-model="repeatPassword"
              filled
              required
              type="password"
              :rules="repeatPasswordRules"
              :label="$t('passwordRestore.repeatPassword')"
            />
            <v-btn large block
                   color="primary"
                   :loading="loading"
                   :disabled="!valid"
                   @click="submit()"
                   v-text="$t('common.save')"
            />
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "ResetPasswordConfirm",
  data() {
    return {
      loading: false,
      valid: true,
      password: null,
      repeatPassword: null,
      passwordRules: [
        (v) => !!v || this.$t('errors.passwordIsRequired'),
        (v) => (v && v.length >= 7) || this.$t('errors.passwordMustBeAtLeastSevenCharacters'),
      ],
      repeatPasswordRules: [
        (v) =>
          v == this.password ||
                this.$t('errors.passwordConfirmationDoesnotMatchPassword'),
      ],
    }
  },

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        const uid = this.$route.params.id
        const token = this.$route.params.token

        this.loading = true
        this.$http.post(`/auth/users/reset_password_confirm/`, { uid, token, new_password: this.password })
          .then(() => {
            this.loading = false
            this.$router.push("/")
            this.$store.commit('pushMessage', {
              text: this.$t('infoMessages.passwordHasBeenChanged'),
            })
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.page{
    width: 100%;
    background: #000;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    .v-card{
        width: 100%;
        max-width: 400px;
    }
}
</style>
